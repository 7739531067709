<template>
  <div>
    <div>
      <i :class="trigger.icon"></i> {{ trigger.label }}
      <span v-if="eventsLabel" class="keywords-span">( {{ eventsLabel }} )</span>
    </div>

    <b-modal id="date-modal" v-model="isShowModal" title="GotoWebinar" size="lg">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label>GotoWebinar Events</label>
            <multiselect v-model="automation.options.events" :options="eventList" :multiple="true" track-by="id"
              placeholder="Select Events" label="name"></multiselect>
          </div>
        </div>
        <div class="col-md-12">
          <div v-if="showOptions" class="form-group">
            <label>GoTo account <span v-b-tooltip.hover class="cursor-pointer"
                title="You can leave this blank to use system default email client."><i
                  class="uil uil-question-circle"></i></span></label>
            <multiselect v-model="automation.options.integration_user" :options="options" :multiple="false" :allow-empty="false"
              track-by="id" placeholder="Select Account" label="name" @input="onAccountChange"></multiselect>
          </div>
        </div>
        <div class="col-md-12">
          <label>Webinars <span v-b-tooltip.hover class="cursor-pointer"
              title="You can leave this blank to listen any in the list."><i
                class="uil uil-question-circle"></i></span></label>
          <div class="text-center">
            <b-spinner v-if="loadingWebinars" small></b-spinner>
            <multiselect v-else v-model="automation.options.webinar" :options="webinars" :multiple="true" track-by="id"
              placeholder="Select Webinars" label="name"></multiselect>
          </div>
        </div>
      </div>

      <template v-slot:modal-footer>
        <b-button variant="primary" @click="save">Save</b-button>
      </template>
    </b-modal>
  </div>
</template>
    
<script>
import Vue from 'vue'
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect
  },

  props: {
    automation: {
      type: Object,
      default: null,
    },
    blocks: {
      type: Array,
      default: null,
    },
    trigger: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      isShowModal: false,
      eventList: [
        { id: 'registrant.added', name: 'Registrant Added' },
      ],
      eventsSelected: [],
      options: [],
      webinars: [],
      loadingWebinars: false,
    }
  },

  computed: {
    eventsLabel() {
      if (this.eventsSelected)
        return this.eventsSelected.map(item => item.name).join(', ');
      return ''
    },

    showOptions() {
      return this.options && this.options.length > 1 
    },
  },

  mounted() {
    this.$parent.$on('showModal', this.showModal)
    this.eventsSelected = this.automation.options.events

    this.eventList = this.eventList.concat([
      { id: 'registrant.joined', name: 'Registrant Attended' },
      { id: 'registrant.notjoined', name: 'Registrant Not Attended' },
    ])
  },

  methods: {
    save() {
      if (!this.automation.options.events || !this.automation.options.events.length) {
        Vue.$toast.open({
          message: 'Events can\'t be empty',
          type: 'error',
          duration: 3000,
        })
        return false;
      }
      this.eventsSelected = this.automation.options.events
      this.isShowModal = false
    },

    showModal() {
      this.isShowModal = true
      this.getSelectOptions()
    },

    getSelectOptions() {
      let queries = {
        key: 'goto'
      }
      this.$store.dispatch('integration/selectOptions', queries)
        .then(options => {
          this.options = options
          if (options && options.length === 1)
          {
            this.automation.options.integration_user = options[0]
          }
          this.getWebinars()
        })
        .catch(() => { })
    },

    getWebinars() {
      this.loadingWebinars = true
      let params = {
        'integration_id': this.automation.options.integration_user ? this.automation.options.integration_user.id : undefined
      }

      this.$store.dispatch('integration/getGotoWebinars', params)
        .then(webinars => {
          this.webinars = webinars
          this.loadingWebinars = false
        })
        .catch(() => {
          this.loadingWebinars = false
        })
    },

    onAccountChange() {
      this.getWebinars()
    }
  },
}
</script>
    
<style lang="scss" scoped>
.keywords-span {
  display: block;
  font-size: 13px;
  font-weight: normal;
}
</style>
    
<style lang="scss">
#date-modal .small-checkbox {
  .custom-checkbox {
    padding-left: 30px;
    margin-bottom: 5px;
  }

  .custom-control-label:before,
  .custom-control-label:after {
    width: 20px;
    height: 20px;
    left: -30px;
    top: 0px;
  }

  .custom-control-input {
    width: 20px;
    height: 20px;
    top: -4px;
  }
}
</style>